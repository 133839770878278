import { useParams, useSearchParams } from "react-router-dom";
import { useLayoutEffect, useRef, useState } from "react";
import { faSmile, faUser, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    CircularProgress,
    Rating,
    TextareaAutosize,
    TextField,
    Typography,
} from "@mui/material";
import Markdown from "markdown-to-jsx";

import * as styles from "./TutorChat.module.scss";
import { useChatMutation } from "./api/chat";
import { useFeedbackMutation } from "./api/feedback";
import { ChatData, ChatSession } from "./types";

import DialogBox from "components/DialogBox/DialogBox";
import { useMessagingContext } from "contexts/MessagingContext";
import { useCollectionQuery } from "hooks/api/collections";

const ChatFeedbackModal = ({
    botId,
    userId,
    sessionId,
    isVisible,
    hideModal,
    conversation,
}: {
    botId: string;
    userId: string;
    sessionId: string;
    isVisible: boolean;
    hideModal: () => void;
    conversation: ChatData[];
}) => {
    const [qualityRating, setQualityRating] = useState(2.5);
    const [accuracyRating, setAccuracyRating] = useState(2.5);
    const [commentText, setCommentText] = useState("");
    const chatFeedbackMutation = useFeedbackMutation();
    const submitFeedback = () => {
        chatFeedbackMutation.mutate(
            {
                botId,
                userId,
                sessionId,
                accuracy: accuracyRating,
                quality: qualityRating,
                comment: commentText,
                conversation,
            },
            {
                onSuccess: hideModal,
            },
        );
    };
    return (
        <DialogBox
            title="Tutor Feedback"
            isOpen={isVisible}
            closeHandler={hideModal}
            styleOverrides={{
                width: "450px",
                height: "fit-content",
                justifyContent: "flex-end",
            }}
            containerStyleOverrides={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
            }}
        >
            <div
                style={{
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div>
                    Thanks for using this Tutor Chatbot. Please provide your
                    review using the stars below, and provide any additional
                    feedback in the textbox.
                </div>
                <div
                    style={{
                        display: "flex",
                        marginTop: 10,
                        marginBottom: 10,
                        gap: 20,
                    }}
                >
                    <div>Quality</div>
                    <Rating
                        name="half-rating"
                        defaultValue={2.5}
                        precision={0.5}
                        value={qualityRating}
                        onChange={(_e, value) =>
                            value && setQualityRating(value)
                        }
                    />
                    <div>Accuracy</div>
                    <Rating
                        name="half-rating"
                        defaultValue={2.5}
                        precision={0.5}
                        value={accuracyRating}
                        onChange={(_e, value) =>
                            value && setAccuracyRating(value)
                        }
                    />
                </div>
                <TextareaAutosize
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    style={{ minHeight: "50px", marginBottom: 10 }}
                />
                <Button onClick={submitFeedback} variant="outlined">
                    Submit Feedback
                </Button>
            </div>
        </DialogBox>
    );
};

const roleStyles = {
    ASSISTANT: {
        icon: faSmile,
        color: "purple",
    },
    USER: {
        icon: faUser,
        color: "green",
    },
    SYSTEM: {
        icon: faWarning,
        color: "red",
    },
};
const ChatBubble = ({ data }: { data: ChatData }) => {
    return (
        <div style={{ display: "flex", padding: 0, margin: 10 }}>
            <FontAwesomeIcon
                icon={roleStyles[data.role].icon}
                color={roleStyles[data.role].color}
                fontSize={20}
                style={{ padding: 10, width: 30, flexShrink: 0 }}
            />
            <div style={{ borderLeft: "2px solid grey", paddingLeft: 10 }}>
                <Markdown>{data.message}</Markdown>
                {data.references && (
                    <>
                        <Typography
                            variant="text-size-Base-bold"
                            component="div"
                            style={{ marginTop: "10px" }}
                        >
                            Sources
                        </Typography>
                        <Markdown
                            options={{
                                overrides: {
                                    a: {
                                        component: CustomAnchorComponent,
                                    },
                                    code: {
                                        component: "span",
                                    },
                                },
                            }}
                        >
                            {data.references}
                        </Markdown>
                    </>
                )}
            </div>
        </div>
    );
};
const ProcessingBubble = () => {
    return (
        <div style={{ display: "flex", padding: 0, margin: 10 }}>
            <FontAwesomeIcon
                icon={roleStyles["ASSISTANT"].icon}
                color={roleStyles["ASSISTANT"].color}
                fontSize={20}
                style={{ padding: 10, width: 30, flexShrink: 0 }}
            />
            <div style={{ borderLeft: "2px solid grey", paddingLeft: 10 }}>
                <CircularProgress size={30} thickness={2} />
            </div>
        </div>
    );
};

const CustomAnchorComponent = ({ children, ...props }) => (
    <a {...props} target="_blank" rel="noopener noreferrer">
        {children}
    </a>
);

const initialChatData: ChatSession = {
    sessionId: 0,
    botId: 0,
    userId: 0,
    initialMessages: [
        {
            id: 0,
            role: "ASSISTANT",
            message: "Welcome to the Tutor Chat!",
        },
    ],
};

const TutorChat = () => {
    const params = useParams();
    const { openAlertMessage } = useMessagingContext();
    const collectionId = params.collectionId
        ? parseFloat(params.collectionId)
        : undefined;
    const { data: collection } = useCollectionQuery(collectionId);
    const botId = params.botId ?? collectionId;
    const userId = params.userId ?? "mvp-test-user";
    const sessionId = useRef(crypto.randomUUID()).current;
    const chatMuation = useChatMutation();
    const [chatData, setChatData] = useState<Array<ChatData>>([]);
    const [inputValue, setInputValue] = useState("");
    const [searchParams] = useSearchParams();
    const enabledAutomatedFeedback = searchParams.get("feedback") === "true";
    const submitMessage = () => {
        if (!collectionId || chatMuation.status === "loading") return;
        console.log({ inputValue });
        const newChatData: ChatData[] = [
            ...chatData,
            { id: chatData.length, message: inputValue, role: "USER" },
        ];
        setChatData(newChatData);
        chatMuation.mutate(
            {
                sessionId,
                botId,
                userId,
                collectionId,
                conversation: newChatData,
            },
            {
                onSuccess: ({ data }) => {
                    setChatData([
                        ...newChatData,
                        {
                            id: chatData.length + 1,
                            message: data.response,
                            references: data.references,
                            role: "ASSISTANT",
                        },
                    ]);
                    console.log({ enabledAutomatedFeedback });
                    console.log({ lenght: chatData.length });
                    if (enabledAutomatedFeedback && chatData.length == 0) {
                        setTimeout(triggerFeedbackModal, 5 * 60 * 1000);
                    }
                },
                onError: () =>
                    setChatData([
                        ...newChatData,
                        {
                            id: chatData.length + 1,
                            message: "AN ERROR OCCURRED",
                            role: "SYSTEM",
                        },
                    ]),
            },
        );
        setInputValue("");
    };
    const triggerFeedbackModal = () => {
        setIsFeedbackModalVisible(true);
        setIsFeedbackButtonVisible(true);
    };
    const chatRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        chatRef.current?.scrollTo({ top: chatRef.current.scrollHeight });
    });
    const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
    const [isFeedbackButtonVisible, setIsFeedbackButtonVisible] =
        useState(false);
    const hideModalHandler = () => {
        openAlertMessage({
            message: "Feedback received, thanks!",
            type: "success",
            open: true,
        });
        setIsFeedbackModalVisible(false);
    };
    return (
        <div
            style={{
                border: "2px solid grey",
                borderRadius: 10,
                margin: 10,
                padding: 0,
                height: "75vh",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div className={styles.headerContainer}>
                <div className={styles.chatTitle}>
                    Educational Tutor: {collection?.title}{" "}
                </div>
                {isFeedbackButtonVisible && (
                    <Button
                        onClick={() => setIsFeedbackModalVisible(true)}
                        variant="outlined"
                        size="small"
                    >
                        Provide Additional Feedback
                    </Button>
                )}
            </div>
            <div
                style={{
                    padding: 10,
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    justifyContent: "space-between",
                }}
            >
                <div ref={chatRef} style={{ overflowY: "auto" }}>
                    {initialChatData.initialMessages.map((el) => (
                        <ChatBubble key={el.id} data={el} />
                    ))}
                    {chatData.map((el) => (
                        <ChatBubble key={el.id} data={el} />
                    ))}
                    {chatMuation.status === "loading" && <ProcessingBubble />}
                </div>
                <div style={{ display: "flex", flexGrow: 0 }}>
                    <TextField
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        fullWidth
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                submitMessage();
                            }
                        }}
                        multiline
                        minRows={1}
                    />
                    <Button onClick={() => submitMessage()}>Send</Button>
                </div>
                <ChatFeedbackModal
                    botId={botId}
                    userId={userId}
                    sessionId={sessionId}
                    isVisible={isFeedbackModalVisible}
                    hideModal={hideModalHandler}
                    conversation={chatData}
                />
            </div>
        </div>
    );
};

export default TutorChat;
