import { useState, useEffect, useMemo, useCallback } from "react";

import { feedbackForm, feedbackMainFrom } from "./FeedBackForm.module.scss";
import {
    useExistingFeedbackQuery,
    useFeedBackOptionsQuery,
    useUpdateFeedBackMutation,
} from "hooks/api/useFeedBackQuery";
import useCurriculumParams from "hooks/useCurriculumParams";
import { QueryResourceResult } from "types";
import { tracker } from "utils/analytics";

import CommentField from "./components/CommentField/CommentField";
import FormFooter from "./components/FormFooter/FormFooter";
import ReasonList from "./components/ReasonsList/ReasonList";
import RequiredAction from "./components/RequiredAction/RequiredAction";

const canSubmit = (
    actionValue: number | undefined,
    reasonsValue: Array<{ id: number; name: string }>,
    commentValue: string,
) => {
    if (Number(actionValue) === 102) return !!commentValue?.length;
    if (reasonsValue) return !!reasonsValue?.length;
    return false;
};

interface Props {
    result: QueryResourceResult;
    setIsFormShowing: (arg0: boolean) => void;
}

const FeedBackForm = ({ result, setIsFormShowing }: Props) => {
    const { queryId } = useCurriculumParams();

    const { data: feedbackOptions, status: feedbackOptionsStatus } =
        useFeedBackOptionsQuery();
    const {
        data: existingFeedback,
        status: existingFeedbackStatus,
        isInitialLoading: isExistingFeedbackInitialLoading,
    } = useExistingFeedbackQuery(
        queryId?.toString(),
        result.id,
        result.feedbackProvided,
    );
    const updateFeedbackMutation = useUpdateFeedBackMutation(
        queryId?.toString(),
        result.id,
        existingFeedback?.id?.toString(),
    );

    const [optionIndex, setOptionIndex] = useState<number>(-1);
    const [actionValue, setActionValue] = useState<number>();
    const updateActionValue = useCallback(
        (newValue: number) => {
            setActionValue(newValue);
            const newOptionIndex =
                feedbackOptions?.findIndex((el) => el.id === newValue) ?? -1;
            if (newOptionIndex >= 0 && optionIndex !== newOptionIndex) {
                // reset
                setOptionIndex(newOptionIndex);
                if (newValue === existingFeedback?.action) {
                    // to existing feedback
                    setReasonsValue(existingFeedback.reasons);
                    setCommentValue(existingFeedback.comment);
                } else {
                    // otherwise blank
                    setReasonsValue([]);
                    setCommentValue("");
                }
            }
        },
        [
            existingFeedback?.action,
            existingFeedback?.comment,
            existingFeedback?.reasons,
            feedbackOptions,
            optionIndex,
        ],
    );

    const [reasonsValue, setReasonsValue] = useState<
        Array<{ id: number; name: string }>
    >([]);
    const updateReasonsValue = (newValue: { id: number; name: string }) => {
        setReasonsValue((originalState) => {
            const index = originalState.map((el) => el.id).indexOf(newValue.id);
            if (index === -1) {
                // Value not found, add it to the array
                return [...originalState, newValue];
            }
            return originalState.filter((value) => value.id !== newValue.id);
        });
    };

    const [commentValue, setCommentValue] = useState("");
    const updateCommentValue = (newValue: string) => {
        setCommentValue(newValue);
    };

    const actionOptions = useMemo(() => {
        return feedbackOptions?.map((el) => ({ id: el.id, name: el.name }));
    }, [feedbackOptions]);

    const selectedOption = useMemo(() => {
        if (optionIndex < 0 || !feedbackOptions) return null;
        return feedbackOptions[optionIndex];
    }, [feedbackOptions, optionIndex]);

    const reasonOptions = selectedOption?.reasons ?? [];

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (actionValue == undefined || !Number.isInteger(actionValue)) return;
        const submitObject = {
            action: actionValue,
            reasons: reasonsValue,
            comment: commentValue,
        };
        updateFeedbackMutation.mutate(submitObject, {
            onSuccess: () => setIsFormShowing(false),
        });
        tracker("Feedback submitted");
    };

    useEffect(() => {
        if (existingFeedbackStatus === "success" && optionIndex === -1) {
            updateActionValue(existingFeedback.action);
            setReasonsValue(existingFeedback.reasons);
            updateCommentValue(existingFeedback.comment);
        }
    }, [
        existingFeedback?.action,
        existingFeedback?.comment,
        existingFeedback?.reasons,
        existingFeedbackStatus,
        optionIndex,
        updateActionValue,
    ]);

    if (feedbackOptionsStatus !== "success" || isExistingFeedbackInitialLoading)
        return (
            <div className={feedbackForm}>
                <div className={feedbackMainFrom}>
                    <div>Loading Feedback...</div>
                </div>
            </div>
        );

    return (
        <form
            onSubmit={handleSubmit}
            name="feedbackForm"
            id="feedBackForm"
            className={feedbackForm}
        >
            <div className={feedbackMainFrom}>
                <RequiredAction
                    value={actionValue}
                    optionsValues={actionOptions}
                    changeHandler={updateActionValue}
                />
                <ReasonList
                    value={reasonsValue}
                    optionsValues={reasonOptions}
                    changeHandler={updateReasonsValue}
                    isRequired={selectedOption?.reasonRequired}
                />
                <CommentField
                    value={commentValue}
                    changeHandler={updateCommentValue}
                    isRequired={selectedOption?.commentsRequired}
                />
            </div>
            <FormFooter
                setIsFormShowing={setIsFormShowing}
                isSubmitButtonEnabled={canSubmit(
                    actionValue,
                    reasonsValue,
                    commentValue,
                )}
            />
        </form>
    );
};

export default FeedBackForm;
