import { useMutation } from "@tanstack/react-query";
import { axiosAPI } from "hooks/api/axiosAPI";
import { ChatData } from "../types";

export const useChatMutation = () => {
    return useMutation(
        async ({
            botId,
            userId,
            sessionId,
            collectionId,
            conversation,
        }: {
            botId: string;
            userId: string;
            sessionId: string;
            collectionId: number;
            conversation: ChatData[];
        }) => {
            return await axiosAPI
                .getInstance()
                ?.post<{
                    response: string;
                    references: string;
                }>(`/content/tutor`, {
                    botId,
                    userId,
                    sessionId,
                    collectionId,
                    conversation,
                });
        },
    );
};
